// Overrides for featured topics grid on home page.

.featured-topic {
  .no-icon-spacing {
    height: 100%;
    min-height: 80px;

    .subhead-4 {
      margin-right: 0;
    }

    .icon {
      height: 1.25rem;
      margin: 0 0 0 0.25rem;
      width: 1.25rem;
    }
  }
}
